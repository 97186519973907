<template>
  <div class="py-6 w-full px-3">
    <div v-if="!isError">
    <h1 class="text-xl text-left font-bold flex mb-2 -mt-2">Approval Flow</h1>
    <div class="border mb-4" />
    <Card class="p-5 mb-5">
      <div>
        <h1 class="text-left font-bold flex mb-0 -mt-2">
          Approval Setup for Events & Actions Workflows
        </h1>
        <p>
          By Default all request goes to the root Admin. However, you can Setup
          Approval and assign individual responsible using the Approval flow
          below.
        </p>
      </div>
      <template v-slot:footer>
        <card-footer
          printcard
          reloadcard
          @print="print"
          @reload="reload()"
          @sortType="handleSort($event)"
          @searchResult="handleSearch($event)"
        />
      </template>
    </Card>
    <div>
      <Table
        :headers="headers"
        :items="approvalData"
        style="width:100%; height:auto"
        class="w-full"
        aria-label="approval table"
        :loading="loading"
        :has-checkbox="false"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.name" class="text-darkPurple">
            {{ item.data.name }}
          </span>
          <span v-if="item.module" class="text-darkPurple">
            {{ item.data.module.name }}
          </span>
          <span v-if="item.submodule" class="text-darkPurple">
            {{ item.data.submodule.name }}
          </span>
          <span v-if="item.workFlowCount" class="text-darkPurple">
            {{ item.data.workFlowCount }}
          </span>
          <span v-if="item.id" class="text-darkPurple">
            <Menu left top="-150" margin="24" class="my-2 p-0">
              <template v-slot:title>
                <icon icon-name="more_icon" size="xs" />
              </template>
              <div style="width: 220px; height: auto" class="py-3 px-2">
                <div
                  class="flex w-full py-1 px-3 more h-8 cursor-pointer"
                  @click="viewWorkFlow(item)"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name=" text-mediumSeaGreen mr-2"
                    size="xs"
                  />
                  <p class="pt-1 text-sm">View Workflow</p>
                </div>
                <div
                  class="flex py-1 px-3 more h-8 cursor-pointer"
                  @click="workFlowCreate(item)"
                >
                  <icon
                    icon-name="icon-add"
                    class-name="mr-2 text-blueCrayola"
                    size="xs"
                  />
                  <p class="pt-1 text-sm">Create New Workflow</p>
                </div>
              </div>
            </Menu>
          </span>
        </template>
      </Table>
    </div>
    </div>
    <div v-else>
      <error-page />
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Approval",
  components: {
    Card,
    CardFooter,
    ErrorPage,
    Table,
    Menu,
  },
  data() {
    return {
      loading: true,
      headers: [
        { title: "Events|Actions", value: "name" },
        { title: " Module ", value: "module" },
        { title: "Sub Modules", value: "submodule" },
        { title: "Workflows ", value: "workFlowCount" },
        { title: "", value: "id", image: true },
      ],
      approvalData: [],
      isError: false
    };
  },
  methods: {
    getAllApproval() {
      this.$_getApproval().then((result) => {
        this.approvalData = result.data.workFlow;
        this.loading = false;
      });
    },

    async workFlowCreate(item) {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "createNewWorkflowSub-category"
        );
        this.$router.push({
          name: "NewWorkflow",
          params: {
            actionId: item.data.id,
            actionSlug: item.data.slug,
          },
          query: { actionName: item.data.name },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async viewWorkFlow(item) {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "viewWorkflowCategory"
        );
        this.$router.push({
          name: "ApprovalWorkFlow",
          params: {
            actionId: item.data.id,
            actionSlug: item.data.slug,
          },
          query: {
            name: item.data.name,
            actionSlug: item.data.slug,
          },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
  },
  async mounted() {
      try {
        await this.$handlePrivilege(
          "approvalWorkflowHR",
          "accessApprovalWorkflowSettings"
        );
        this.getAllApproval();
      } catch (error) {
        this.isError = true;
      }
  },
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
</style>
